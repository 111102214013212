<template>
  <div class="max-width-1300">
   
    <!-- ========= HEADER ========= -->
    <div class="d-flex align-items-end justify-content-between">
      <h2 class="text-success font-weight-bolder mb-0">HISTORY</h2>
      
      <div align="right">
        <div>History from</div>
        <b-form-datepicker 
          v-model="pickedDate" 
          :max="currentDate"
          :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric', 'weekday': undefined }"
          dropleft 
          @input="fetchHistory()"
        >
          <template #button-content>
            <b-icon icon="calendar2-event" variant="success"/>
          </template>
        </b-form-datepicker>
      </div>
    </div>
    <!-- ========= HEADER ========= -->

    <div v-if=" Array.isArray(getTransmissionHistory.data) " :key="`history-container-${pickedDate}`">

      <b-card v-for="(day, dIdx) in getTransmissionHistory.data" :key="'history-day-' + dIdx" class="mt-1">
        
        <div class="d-flex justify-content-between align-items-start mb-1">
          <h3 class="mb-0">
            <b-icon icon="clock" variant="info" class="mr-50"/>
            {{day.length}} change<span v-if="day.length > 1">s</span>
          </h3>

          <h3 class="mb-0">
          {{formatDate( day[0].datetime, 'date')}}
          </h3>
        </div>
        
        <div v-for="(log, lIdx) in day" :key="`history-log-${lIdx}-${dIdx}`" class="mx-1">
          
          <div class="d-flex justify-content-between">
            
            <div class="history-log-container">
              <span>
                {{log.user.fullname}}
              </span>
              
              <b-icon icon="arrow-right-short" class="mx-2" scale="1.4" variant="secondary"/>

              <span class="font-weight-bolder w-100px" :class="'text-' + operation[log.message].color">
                {{ operation[log.message].label }}
              </span>

              <b-icon icon="arrow-right-short" class="mx-2" scale="1.4" variant="secondary"/>

              <span>
                {{log.table}}
              </span>
            </div>

            <div>
              {{formatDate(log.datetime, 'time')}}
            </div>

          </div>

        </div>

      </b-card>

      <div v-if="getTransmissionHistory.data.length == 0">
        <h1 align="center" class="my-5 text-secondary">
           <b-icon icon="circle-fill" scale="0.2"/>
            No Logs
           <b-icon icon="circle-fill" scale="0.2"/>
        </h1>

      </div>
    </div>
    
    <div align="center" v-if="getTransmissionHistory.isLoading" class="py-5">
      <b-spinner variant="success"/>
    </div>
    <br v-for="i in 5">
    <div v-observe-visibility="attemptLoadMore">
    </div>
  </div>
</template>

<script>
import { 
  BCol,
  BRow, 
  BButton, 
  BButtonGroup, 
  BSkeleton, 
  BModal, 
  BSpinner, 
  BFormInput, 
  BBadge, 
  BCollapse,
  BFormDatepicker,
  BCard,
} from "bootstrap-vue";

import { makeToast } from "@/layouts/components/Popups";
import { mapGetters } from 'vuex'
import moment from 'moment';
import { makeDestructurable } from '@vueuse/core';

export default {
  components: {
    BCol,
    BRow, 
    BButton, 
    BButtonGroup, 
    BSkeleton, 
    BModal, 
    BSpinner, 
    BFormInput, 
    BBadge, 
    BCollapse,
    BFormDatepicker,
    BCard,
  },
  data() {
    return {
      pickedDate: undefined,
      currentDate: undefined,
      operation: {
        INSERT: {color:'success', label: "INSERT"},
        UPDATE: {color:'warning', label: "UPDATE"},
        CHANGER: {color:'warning', label: "UPDATE"},
        DELETE: {color:'danger', label: "DELETE"},
      }
    }
  },
  mounted () {
    this.currentDate = new Date().toISOString().slice(0, 10)
    this.pickedDate = structuredClone(this.currentDate)

    this.fetchHistory();
  },
  computed: {
    ...mapGetters(['getTransmissionHistory'])
  },
  methods: {
    attemptLoadMore(visible){
      if (visible == true && !this.getTransmissionHistory.isLoading){
        this.fetchHistory()
      }
    },
    fetchHistory(){
      this.$store.dispatch('fetchTransmissionHistory', {transmissionID: this.$route.params.transmissionID, date: this.pickedDate}).then((resp)=>{
      })
    },
    formatDate(date, type ='date'){
      let m = new moment(date)
      let r 
      switch (type) {
        case 'date':
          r = m.calendar(null, {
            lastDay : '[Yesterday]',
            sameDay : '[Today]',
            nextDay : '[Tomorrow]',
            lastWeek : '[last] dddd',
            nextWeek : 'dddd',
            sameElse : 'L'
          })
          break;
        case 'time':
          r = m.format('h:mm a')
          break
      }

      return r
    }
  },

}
</script>

<style lang="scss" scoped>
.w-100px{
  width: 100px;
  display: inline-block;
  text-align: center;
}

.max-width-1300{
  max-width: 1300px;
  margin: auto;
}

.history-log-container{
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}
</style>